* {
  font-family: 'Space Mono', monospace;
}

.App {
  text-align: left;
  margin-top: 50px;
  margin-left: 35px;
}

.App-logo {
  height: 25px;
  pointer-events: none;
}

.ide-image {
  height: 204px;
  padding-bottom: 20px;
}

.App-image {
  height: 204px;
  padding-bottom: 20px;
}

.hi-user {
  padding: 20px 0px;
  font-weight: 700;
  font-size: 24px;
  color: #203A61;
}

.Row {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #203A61;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .App-header {
    flex-direction: column;
    font-size: 20px;
  }
}
